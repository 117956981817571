<template>
  <div>
    <template>
      <div>
        <v-breadcrumbs
          :items="breadcrumb"
          class="px-0 pt-0"
        >
        </v-breadcrumbs>
      </div>
    </template>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali

    </a>
    <h3 class="my-8">
      Edit Arsip Soal
    </h3>
    <v-card>
      <v-card-text class="pa-8">
        <div class="mb-4">
          <h4>Soal</h4>
        </div>
        <div>
          <v-textarea
            v-model="archiveQuestion.name"
            :rules="[validation.required]"
            label="Soal"
            outlined
            dense
          >
          </v-textarea>
        </div>
        <div class="mb-4">
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <FileInput
            v-model="initialFile"
            dense
            outlined
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            label="Gambar"
            @input="getFile"
          >
          </FileInput>
        </div>
        <div>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-5 mt-6">
                Kunci Jawaban
              </h4>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="archiveQuestion.choice_a"
                      :rules="[validation.required]"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        A.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="archiveQuestion.choice_c"
                      :rules="[validation.required]"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        C.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-0">
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="archiveQuestion.choice_b"
                      :rules="[validation.required]"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        B.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="archiveQuestion.choice_d"
                      :rules="[validation.required]"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        D.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Jawaban Yang Benar
              </h4>
              <div class="">
                <v-text-field
                  v-model="archiveQuestion.answer"
                  :rules="[validation.required]"
                  label="Jawaban yang benar"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Point Jawaban Yang Benar
              </h4>
              <div>
                <v-text-field
                  v-model="archiveQuestion.point"
                  :rules="[validation.required]"
                  label="Point"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-4">
                Pembahasan
              </h4>
              <div>
                <v-textarea
                  v-model="archiveQuestion.discussion"
                  :rules="[validation.required]"
                  label="Pembahasan"
                  outlined
                  dense
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-card-actions class="d-flex justify-center mt-10 mb-5">
            <v-btn
              color="primary"
              large
              :loading="isLoadingButton"
              :disabled="!formValidLesson"
              @click="updateArchiveQuestion(archiveQuestion.uuid)"
            >
              Update
            </v-btn>
            <v-btn
              color="primary"
              large
              outlined
              @click="returnBack"
            >
              Batal
            </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import FileInput from '../../components/FileInput.vue'
// import { ref, watch, onMounted } from '@vue/composition-api'

export default {
  name: 'EditQuestionArchive',
  components: {
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiImageMultipleOutline,
        mdiDotsVertical,
      },
      validation: {
        required,
        integerValidator,
      },
      breadcrumb: [
        {
          text: 'Arsip Soal',
          disabled: false,
          href: '/archive-teacher',
        },
        {
          text: 'Edit Arsip Soal',
          disabled: true,
        },
      ],
      formValidLesson: false,
      isLoadingButton: false,
      isDisabledButton: false,
      archiveQuestion: {
        name: '',
        image: null,
        choice_a: '',
        choice_b: '',
        choice_c: '',
        choice_d: '',
        is_essay: '0',
        discussion: '',
        answer: '',
        point: 0,
      },
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.archiveQuestion.image === 'string') {
          return new File([this.archiveQuestion.image], this.archiveQuestion.image, { type: 'text/plain' })
        }

        return this.archiveQuestion.image
      },
      set() {
        return this.archiveQuestion.image
      },
    },
  },
  watch: {
    archiveQuestion: {
      handler() {
        const valid = []
        const requiredField = ['name', 'choice_a', 'choice_b', 'choice_c', 'choice_d', 'answer', 'point', 'discussion']
        Object.entries(this.archiveQuestion).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidLesson = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.getArchiveQuestion(this.$route.params.uuid)
  },
  methods: {
    resetForm() {
      this.archiveQuestion.name = ''
      this.archiveQuestion.image = null
      this.archiveQuestion.choice_a = ''
      this.archiveQuestion.choice_b = ''
      this.archiveQuestion.choice_c = ''
      this.archiveQuestion.choice_d = ''
      this.archiveQuestion.answer = ''
      this.archiveQuestion.point = ''
      this.archiveQuestion.discussion = ''
    },
    getFile(data) {
      this.archiveQuestion.image = data
    },
    async getArchiveQuestion(uuid) {
      await this.$services.ApiServices.get('archivequestion', uuid).then(
        ({ data }) => {
          this.archiveQuestion = data.data
        },
        err => console.error(err),
      )
    },
    async updateArchiveQuestion(uuid) {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.archiveQuestion.uuid = uuid
      this.formData = new FormData()
      this.formData.append('name', this.archiveQuestion.name)
      this.formData.append('image', this.archiveQuestion.image)
      if (typeof this.archiveQuestion.image === 'string' || this.archiveQuestion.image === null) {
        this.formData.delete('image')
      }
      this.formData.append('choice_a', this.archiveQuestion.choice_a)
      this.formData.append('choice_b', this.archiveQuestion.choice_b)
      this.formData.append('choice_c', this.archiveQuestion.choice_c)
      this.formData.append('choice_d', this.archiveQuestion.choice_d)
      this.formData.append('answer', this.archiveQuestion.answer)
      this.formData.append('point', this.archiveQuestion.point)
      this.formData.append('discussion', this.archiveQuestion.discussion)
      await this.$services.ApiServices.update('archivequestion', this.formData, uuid).then(
        ({ data }) => {
          this.archiveQuestion = data.data
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
        },
        err => {
          this.isLoadingButton = false
          this.isDisabledButton = false
          console.error(err)
        },
      )
      this.returnBack()
      this.resetForm()
    },
    returnBack() {
      this.$router.back()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style >
</style>
